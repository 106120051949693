.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.paymentWrapper {
    width: 100%;
    padding: 12px;
    border: 1px solid #eee;
}

.stripeForm {
    width: 100%;
}


.StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    font-size: 1em;
    font-family: "Source Code Pro", monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
        rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
}

input::placeholder {
    color: #aab7c4;
}

input:focus,
.StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
        rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
    padding: 0;
}

.StripeElement.PaymentRequestButton {
    height: 40px;
}

p.poweredbystrip {
    font-size: 12px;
    margin-top: 0;
}

img.poweredbystrip {
    max-width: 148px;
    border-radius: 10px;
    border: 1px solid #000;
    padding: 2px 8px;
}

.backtoselection {
    width: 100%;
    text-align: right;
    padding-top: 30px;
}

.backtoselection button {
    padding: 10px;
    background-color: #000000;
    border: 1px solid #eee;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
}

.stripeForm button[type=submit] {
    padding: 12px;
    background-color: #fff;
    border: 1px solid #000000;
    border-radius: 10px;
}

.stripeForm button[type=submit]:hover {
    background-color: #000;
    color: #fff;
    cursor: pointer;
}

.stripeForm #cardholder {
    width: 100%;
    height: 44px;
    font-size: 15px;
    border: 1px solid #002554;
    padding: 12px;
    border-radius: 5px;
}

.productDetails h4,
.stripeForm h4 {
    margin: 12px 0 6px 0;
}

.productDetails p {
    margin: 6px 0 12px 0;
    font-size: 14px;
}

.stripeForm .section {
    display: flex;
    flex-wrap: wrap;
}

.stripeForm .section .sub1 {
    flex: 50%;
}

.stripeForm .section .sub2 {
    flex: 19%;
    text-align: right;
}

.paymentWrapper .paymentSuccessful {
    color: green;
    text-align: right;
    font-weight: bold;
}

.paymentWrapper .paymentError {
    color: #ad062b;
    text-align: right;
    font-weight: bold;
}