.result-text-container {
  display: flex;
  flex-direction: column;

  .life-insurance-bubble {
    margin-bottom: 10px;
    min-width: 200px;
  }

  .illustration-link {
    margin-top: 10px;
    width: 52%;
  }

  @media screen and (max-width: 767px) {
    .life-insurance-bubble {
      max-width: 100% !important;
      width: 100%;

      .illustration-link {
        width: 100%;
      }
    }
  }
}
