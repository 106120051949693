@mixin moving-animation($duration) {
  animation-name: moving-animation;
  animation-duration: $duration;
  animation-iteration-count: infinite;
}

@mixin moving-object($height, $width, $duration) {
  max-width: $width;
  max-height: $height;
  width: 100%;
  left: -120vw;
  display: block;
  position: absolute;

  @include moving-animation($duration);
}

@mixin themable(
  $theme-name,
  $primary-color,
  $page-background,
  $page-airplane,
  $page-cloud
) {
  .#{$theme-name} {
    .page-content.not-found {
      h1 {
        color: $primary-color;
      }
    }

    .page-background {
      background-image: url($page-background);

      .page-airplane {
        background-image: url($page-airplane);
      }

      .page-cloud-1,
      .page-cloud-2 {
        background-image: url($page-cloud);
      }
    }

    .conversation-wrapper {
      padding: 40px 0px;
      overflow-y: auto;
      max-height: calc(100% - 30px);
      height: 100%;

      &.max-height {
        max-height: 100% !important;
      }

      .conversation-body {
        margin: auto;

        .conversation-status-wrapper {
          margin-bottom: 10px;
          height: 81px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          .conversation-status {
            font-weight: 500;
            font-size: 17px;
            text-align: center;
            line-height: 18.29px;
            margin-top: 0px;
            margin-bottom: 9px;

            .conversation-status-avatar {
              vertical-align: middle;
              margin-right: 11px;
            }
          }
        }

        .typeahead {
          .conversation-typeahead,
          .conversation-message-button-wrapper {
            display: none;
          }

          &:last-child {
            .conversation-message-button-wrapper,
            .conversation-typeahead {
              display: block;
            }
          }
        }

        .conversation-message {
          display: flex;
          margin-bottom: 10px;
          transition: opacity 0.5s, background-color 0.75s, color 0.75s;

          &:last-child {
            .conversation-message-button {
              display: block !important;
            }
          }

          .conversation-message-avatar {
            min-width: 40px;
            min-height: 40px;
            background-repeat: no-repeat;
            position: relative;
            align-self: flex-end;
            opacity: 0;
            animation: avatar-slide-down 0.2s ease-in-out;
            animation-fill-mode: forwards;
            box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 30%);
            background-color: white;
            border-radius: 50%;
            background-size: cover;

            &.visible {
              opacity: 1;
            }
          }

          &.conversation-message-agent,
          &.conversation-message-bot {
            .conversation-message-avatar {
              margin-right: 20px;
            }

            .conversation-message-bubble {
              background-color: $white-color;
              animation: message-left-in 0.5s ease-in-out;
              box-shadow: 0 1px 28px 0 rgba($color: $black-color, $alpha: 0.22);

              .conversation-message-bubble-video,
              .conversation-message-bubble-text {
                color: $black-color;
              }
            }
          }

          &.conversation-message-user {
            flex-direction: row-reverse;

            .conversation-message-avatar {
              display: none;
            }

            .conversation-message-bubble {
              background-color: $primary-color;
              animation: message-right-in 0.5s ease-in-out;
              box-shadow: 0 1px 28px 0 rgba($color: $primary-color, $alpha: 0.4);

              .typing-animation-wrapper {
                .typing-spinner {
                  .ball {
                    background: $white-color;
                  }
                }
              }

              .conversation-message-bubble-video,
              .conversation-message-bubble-text {
                color: $white-color;
              }
            }
          }

          .conversation-message-bubble {
            padding: 15px 20px;
            max-width: 60%;
            height: auto;
            border-radius: 17px;
            min-width: 80px;
            min-height: 52px;
            position: relative;
            overflow-x: auto;
            overflow-y: hidden;
            // box-sizing: content-box;

            .primary-link {
              color: $primary-color;
            }

            .conversation-message-bubble-video {
              font-size: 17px;
              font-weight: 500;
              width: 100%;
              height: 100%;
              overflow: hidden;
            }

            .conversation-message-bubble-text {
              font-size: 17px;
              font-weight: 500;
              width: 0;
              height: 0;
              overflow: hidden;
              animation: message-show 0s ease-in-out;
              animation-delay: 0.8s;
              animation-fill-mode: forwards;
            }
          }
        }
      }
    }

    .conversation-footer {
      min-height: 80px;
      display: flex;
      flex-direction: column;
      opacity: 0;
      align-items: center;
      animation: button-slide-up 0.5s ease-in-out;
      animation-delay: 0.8s;
      animation-fill-mode: forwards;
      position: relative;

      .conversation-message-button-wrapper {
        width: 100%;

        .conversation-message-button {
          margin-left: 0px;
          margin-top: 10px;
        }
      }

      .conversation-typeahead-suggestions-list {
        z-index: 1;
        width: 100%;
        overflow-y: auto;
        list-style-type: none;
        text-align: left;
        margin: 0;
        padding: 0;
        border-top: 1px solid gray;
        background: $white-color;
        box-shadow: 0 1px 28px 0 rgba($color: $black-color, $alpha: 0.22);
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;

        &::before {
          content: "";
        }

        li {
          padding: 10px 30px;
          cursor: pointer;
          font-family: "Montserrat", sans-serif;
          font-weight: 500;
          font-size: 17px;
        }
      }

      &.typeahead {
        min-height: unset;
        margin-bottom: 10px;

        ~ .conversation-message-button-wrapper .conversation-message-button {
          margin-top: 0px;
        }
      }

      &:not(:last-child):not(.typeahead) {
        display: none;
      }

      &.conversation-footer-agent,
      &.conversation-footer-user {
        float: right;
      }

      &.conversation-footer-bot {
        margin-left: 60px;
      }

      .conversation-input-wrapper {
        width: 100%;
        border-radius: 30px;
        background: $white-color;
        display: flex;
        border: 2px solid $primary-color;
        align-items: center;
        box-shadow: 0 1px 28px 0 rgba($color: $black-color, $alpha: 0.22);

        .conversation-input-startAdornment {
          padding-left: 10px;
          padding-right: 10px;
          font-family: "Montserrat", sans-serif;
          font-weight: 500;
          font-size: 17px;

          + .conversation-input {
            padding-left: 0px;
          }
        }

        .conversation-input-endAdornment {
          padding-left: 10px;
          padding-right: 5px;
          font-family: "Montserrat", sans-serif;
          font-weight: 500;
          font-size: 17px;
          max-width: 60px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .conversation-input {
          flex: 1;
          padding: 12px 30px;
          border: none;
          border-radius: inherit;
          font-family: "Montserrat", sans-serif;
          font-size: 17px;
          font-weight: 500;
          outline: none;
          opacity: 0;
          animation: input-slide-up 0.5s ease-in-out;
          animation-delay: 0.8s;
          animation-fill-mode: forwards;
          -moz-appearance: textfield;

          + .conversation-input-endAdornment {
            padding-left: 0px;
          }

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }

        .conversation-input-btn {
          font-family: "Montserrat", sans-serif;
          font-size: 17px;
          font-weight: 600;
          color: $white-color;
          background-color: $primary-color;
          border: none;
          margin-right: 20px;
          padding: 5px 10px;
          border-radius: 4px;
          box-shadow: 0px 12px 18px -6px rgba($color: $primary-color, $alpha:
                0.53);
          cursor: pointer;
        }
      }
    }

    .conversation-message-button-wrapper {
      &.conversation-message-button-wrapper-user {
        .conversation-message-button {
          justify-content: flex-end;
          display: flex;
          max-width: unset;
        }
      }

      &.conversation-message-button-wrapper-bot:last-child {
        .conversation-message-button {
          display: block !important;
        }

        // .conversation-footer {
        //   display: flex !important;
        // }
      }

      .conversation-message-bubble {
        background-color: $white-color;
      }

      .conversation-message-avatar {
        margin-right: 20px;
      }

      .conversation-message-button {
        margin-top: 20px;
        margin-left: 60px;
        max-width: 80%;
        opacity: 0;
        animation: button-slide-up 0.5s ease-in-out;
        animation-delay: 0.8s;
        display: none;
        animation-fill-mode: forwards;

        .conversation-message-option {
          min-width: 80px;
          text-align: center;
          background-color: $primary-color;
          border-radius: 30px;
          border-width: 3px;
          border-color: $primary-color;
          border-style: solid;
          cursor: pointer;
          color: $white-color;
          font-size: 17px;
          font-weight: 500;
          display: inline-block;
          padding: 15px;
          margin-right: 10px;
          margin-bottom: 10px;
          box-shadow: 0px 12px 18px -6px rgba($color: $primary-color, $alpha:
                0.53);
          transition: all 300ms ease 0ms;

          &:hover {
            transform: scale(1.1);
            background-color: $white-color;
            border-color: $primary-color;
            color: $primary-color;
          }
        }
      }
    }

    .typing-animation-wrapper {
      position: absolute;

      &:not(.infinite) {
        animation: typing-wrapper-fade-out 0.3s ease-in-out;
        animation-delay: 0.5s;
        animation-fill-mode: forwards;
      }

      .typing-spinner {
        .ball {
          display: inline-block;
          width: 9px;
          height: 9px;
          margin-right: 5px;
          border-radius: 50%;
          background-color: $primary-color;

          &:nth-child(1) {
            opacity: 0.9;
            animation: typing-ball 0.9s linear infinite;
          }

          &:nth-child(2) {
            opacity: 0.9;
            animation: typing-ball 0.9s 0.225s linear infinite;
          }

          &:nth-child(3) {
            opacity: 0.6;
            animation: typing-ball 0.9s 0.45s linear infinite;
          }
        }
      }
    }

    .react-datepicker-wrapper {
      padding: 15px 20px !important;

      .react-date-picker-talk-button,
      .react-date-picker-confirm-button {
        margin-bottom: 10px;
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        font-weight: 600;
        color: $white-color;
        background-color: $primary-color;
        border: 2px solid $primary-color;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        box-shadow: 0 1px 12px 0 rgba($color: $primary-color, $alpha: 0.22);
        transition: all 300ms ease 0ms;

        &:hover {
          color: $primary-color;
          background-color: transparent;
        }
      }

      .react-date-picker-confirm-button {
        float: right;
      }

      .react-datepicker {
        border: none;
        font-family: "Montserrat", sans-serif !important;
        display: flex;
        border-radius: 0px;
        flex-direction: column;

        .react-datepicker__navigation--next {
          right: 2px;
        }

        .react-datepicker__month-container {
          flex: 1;

          .react-datepicker__day--selected {
            background-color: $primary-color;
          }

          .react-datepicker__day--keyboard-selected,
          .react-datepicker__day--selected {
            background-color: $primary-color;
            color: $white-color;
            font-weight: 700;
          }

          .react-datepicker__header {
            border: none;
            background-color: $white-color;

            .react-datepicker__current-month {
              background-color: $white-color;
              color: $primary-color;
              margin-bottom: 10px;
              font-size: 16px;
              font-weight: 500;
            }

            .react-datepicker__day-names {
              background-color: $white-color;
              border: none;
              display: flex;

              .react-datepicker__day-name {
                text-transform: uppercase;
                font-weight: normal;
                color: #ccc;
                flex: 1;
              }
            }
          }

          .react-datepicker__week {
            display: flex;

            .react-datepicker__day {
              flex: 1;
              font-size: 16px;
              font-weight: 500;
            }
          }
        }

        .react-datepicker__time-container {
          border: none;
          width: 100%;
          display: flex;
          flex-direction: column;

          .react-datepicker__time {
            display: flex;
            flex: 1;
            align-items: center;
            overflow-y: hidden;
            overflow-x: auto;

            .react-datepicker__time-box {
              max-width: 100%;
              width: unset;

              .react-datepicker__time-list {
                display: flex;
                flex: 1;
                overflow-y: hidden;
                height: 50px !important;
                align-items: center;

                &::-webkit-scrollbar {
                  display: block;
                  height: 5px;
                }

                &::-webkit-scrollbar-button {
                  display: none;
                }

                &::-webkit-scrollbar-track,
                &::-webkit-scrollbar-track-piece {
                  background-color: transparent;
                }

                &::-webkit-scrollbar-thumb {
                  background-color: rgba(0, 0, 0, 0.2);
                  border: 1px solid hsla(0, 0%, 100%, 0.2);
                  border-radius: 24px;
                }

                .react-datepicker__time-list-item {
                  font-size: 16px;
                  font-weight: 500;
                }

                .react-datepicker__time-list-item--disabled {
                  display: none;
                }

                .react-datepicker__time-list-item--selected {
                  background-color: $primary-color;
                  color: $white-color !important;
                  font-weight: 700 !important;
                  font-weight: 500;
                  border-radius: 4px;
                }
              }
            }
          }

          .react-datepicker__header--time {
            background-color: $white-color;
            border: none;

            .react-datepicker-time__header {
              color: $primary-color;
              font-size: 16px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
