@media (max-width: 424px) {
  .criteria-result-table {
    border: 0;

    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    tr {
      display: block;
      margin-bottom: 0.625em;
      text-align: left;
    }

    td {
      display: block;
      font-size: 0.8em;
      text-align: right;
      text-align: left;
    }

    td::before {
      /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }

    td:last-child {
      border-bottom: 0;
    }
  }

  .criteria-action-container {
    flex-direction: column;
  }

  .conversation-body,
  .conversation-footer {
    width: 90%;

    &.conversation-footer-bot {
      margin-left: 0px !important;
      width: 100% !important;
    }

    .conversation-input {
      font-size: 13px;
    }

    .conversation-input {
      font-size: 14px;
    }
  }

  .conversation-wrapper
    .conversation-body
    .conversation-message
    .conversation-message-bubble {
    max-width: 100%;
  }

  .react-date-picker-confirm-button {
    float: unset !important;
  }
}

@media (min-width: 425px) {
  .conversation-body,
  .conversation-footer {
    width: 80%;
    max-width: 700px;
  }

  .conversation-wrapper
    .conversation-body
    .conversation-message
    .conversation-message-bubble {
    max-width: 100%;
  }

  .react-date-picker-confirm-button {
    float: unset !important;
  }
}

@media (min-width: 768px) {
  .conversation-body,
  .conversation-footer {
    width: 80%;
  }

  .conversation-wrapper
    .conversation-body
    .conversation-message
    .conversation-message-bubble {
    max-width: 60%;
  }

  .react-datepicker-buttons {
    display: flex;
    justify-content: space-between;
  }
}

@media (min-width: 1024px) {
  .conversation-body,
  .conversation-footer {
    width: 60%;
  }

  .conversation-wrapper
    .conversation-body
    .conversation-message
    .conversation-message-bubble {
    max-width: 60%;
  }
}
