@keyframes moving-animation {
  from {
    left: -120vw;
  }

  to {
    left: 120vw;
  }
}

@keyframes message-left-in {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes message-right-in {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes typing-ball {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes typing-wrapper-fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes message-show {
  0% {
    width: 0;
    height: 0;
  }

  100% {
    width: 100%;
    height: 100%;
  }
}

@keyframes button-slide-up {
  0% {
    opacity: 0;
    transform: translateY(28px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes avatar-slide-down {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes input-slide-up {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
