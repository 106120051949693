@import "../../assets/scss/colors";

.criteria-snapshot-container {
  width: 100%;
  margin-top: 10px;
  background: #fff;
  padding: 30px;
  border-radius: 17px;
  box-shadow: 0 1px 28px 0 rgb(0 0 0 / 22%);

  .criteria-action-container {
    display: flex;
    justify-content: space-between;

    .print-to-pdf {
      height: fit-content;
      align-self: center;
      min-width: 150px;
      text-align: center;
      border-radius: 4px;
      border-width: 3px;
      border-style: solid;
      cursor: pointer;
      color: $white-color;
      font-size: 16px;
      font-weight: 500;
      display: inline-block;
      padding: 8px;
      margin-right: 10px;
      margin-bottom: 10px;
      transition: all 300ms ease 0ms;

      &:hover {
        transform: scale(1.1);
        background-color: $white-color;
      }
    }

    .criteria-snapshot-keyword {
      font-size: 16px;
      font-weight: 500;
      color: #465656;
      margin-bottom: 20px;
      font-family: "Montserrat", sans-serif;
    }
  }
}

.criteria-result-container {
  border-collapse: separate;
  overflow-x: auto;

  .criteria-result-table {
    table-layout: fixed;
    border-collapse: collapse;

    .criteria-result-table-header {
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      font-size: 14px;
      padding: 20px 10px;
    }
  }
}

.criteria-header {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
}

.criteria-label {
  margin-bottom: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #000;
  line-height: 20px;

  .criteria-marker {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;

    &.accept {
      background-color: #339b7d;
    }

    &.partial {
      background-color: #f76f31;
    }

    &.not-accept {
      background-color: #ff0000;
    }

    &.not-available {
      background-color: #999;
    }
  }
}

.get-in-touch {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.criteria-title {
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  line-height: 20px;
}
