@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  33% {
    opacity: 1;
  }

  53% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.illustration-page {
  max-width: 100% !important;
  width: 100% !important;
  height: 850px !important;
  padding: 0px !important;
  overflow-y: auto !important;
  background: #f4f4f4 !important;
  overflow-x: hidden !important;

  .illustration-page-loader {
    position: relative;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .load-circle {
      border: 4px solid #a197a5;
      border-radius: 50%;
      animation-name: spin;
      animation-duration: 5000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;

      &::before {
        content: "";
        background-color: #ff9015;
        position: absolute;
        left: 35%;
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
    }

    .load-content {
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      animation: fade 6s infinite;

      h3 {
        color: #fff;
        text-align: center;
        position: relative;
        top: 50%;
        font-size: 28px;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 600;
      }

      &:nth-child(2) {
        animation-delay: -2s;
      }

      &:nth-child(3) {
        animation-delay: -4s;
      }
    }
  }

  .switch-container {
    display: flex;
    width: 60%;
    margin: auto;
    justify-content: space-between;
    align-items: center;
  }

  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 77px;
    height: 42px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 36px;
    width: 36px;
    right: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(-33px);
    -ms-transform: translateX(-33px);
    transform: translateX(-33px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .calculate {
    font-size: 19px;
    font-weight: 600;
    border-radius: 4px;
    outline: none;
    color: #fff;
    padding: 12px 20px;
    height: fit-content;
    display: block;
    cursor: pointer;
    border: none;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .no-result {
    margin-top: 20px;

    .container {
      padding: 10px 20px;
      background: #fff;
    }

    h2,
    p {
      margin: 0px;
      margin-bottom: 10px;
    }
  }

  .green-button {
    font-size: 19px;
    font-weight: 600;
    border-radius: 4px;
    outline: none;
    color: #fff;
    padding: 12px 20px;
    height: fit-content;
    display: block;
    cursor: pointer;
    border: none;
    max-width: max-content;
    margin: 0 auto;
  }

  .header-container {
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 10px 20px;
    background: #f4f4f4;

    .col {
      margin-top: 0px !important;
    }

    .header-info {
      padding: 10px;
    }

    .header-customize-col {
      display: flex;
      align-items: center;
      justify-content: center;

      .header-customize {
        padding: 10px;

        .accordion {
          font-size: 19px;
          font-weight: 600;
          border-radius: 4px;
          outline: none;
          color: #fff;
          padding: 12px 20px;
          height: fit-content;
          display: block;
          cursor: pointer;
          border: none;
        }
      }
    }
  }

  .result-container {
    padding: 10px 20px;
    background: #f4f4f4;
    display: flex;
    flex-direction: column;

    .borrowing {
      text-align: center;
      font-size: 15px;
      font-weight: 600;
    }

    .product-result-summary {
      display: flex;
      gap: 10px;

      .product-result {
        position: relative;
        flex: 50%;

        .best-class-result {
          position: relative;
          padding: 20px;
          text-align: center;
          background: #f8f8f8;
          box-shadow: 3px 3px 3px #d0d0d063;
          cursor: pointer;

          &.active {
            background: #fff;
            box-shadow: none;
          }

          .length {
            font-weight: 600;
            font-size: 20px;
          }

          .mortgage-line {
            border-top: 2px solid #afaca9;
            margin-top: 25px;
            width: 35px;
            margin-bottom: 25px;
          }

          .length-amount {
            font-size: 20px;
            font-weight: 600;

            .per {
              font-size: 12px;
              margin-left: 4px;
            }
          }

          .rate {
            font-size: 18px;
            font-weight: 600;
          }
        }
      }
    }

    .triangle-clip {
      background: #fff;
      height: 65px;
      width: 145px;
      margin: auto;
      clip-path: polygon(50% 100%, 0 0, 99% 0);
    }

    .product-result-list {
      display: none;

      &.active {
        display: block;
        animation-name: slide-up;
        animation-duration: 1s;
        animation-timing-function: linear;
      }

      .product-details {
        margin-top: 20px;

        .required-fees {
          padding: 20px;
          background: #fff;

          .subtitle,
          .title {
            margin-top: 0px;
          }

          .subtitle {
            font-size: 12px;
          }

          .basic-row {
            display: flex;
            flex-wrap: wrap;
            margin-right: -15px;
            margin-left: -15px;
            width: 100%;

            .t-col {
              position: relative;
              width: 100%;
              min-height: 1px;
              padding-right: 15px;
              padding-left: 15px;
              flex: 0 0 100%;
              max-width: 100%;
              text-align: center;

              .b-total {
                padding: 40px 20px;
                border-radius: 4px;

                h2 {
                  color: #fff;
                }
              }
            }

            .basic-col {
              position: relative;
              width: 100%;
              min-height: 1px;
              padding-right: 15px;
              padding-left: 15px;
              flex: 0 0 100%;
              max-width: 100%;

              .b-border {
                padding: 20px 0px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                h5,
                h3 {
                  margin: 0px;
                }
              }

              .col {
                margin-top: 0px;

                &:first-child,
                &:nth-child(2),
                &:nth-child(3) {
                  .b-border {
                    border-bottom: 1px dashed #e6e6e6;
                  }
                }

                &:nth-child(2) {
                  border-left: 1px dashed #e6e6e6;
                }
              }
            }

            @media screen and (min-width: 992px) {
              .t-col {
                flex: 0 0 35%;
                max-width: 35%;
                align-self: center;
              }

              .basic-col {
                flex: 0 0 65%;
                max-width: 65%;
              }
            }
          }
        }
      }

      .loan-to-value {
        margin-top: 20px;

        .container {
          padding: 20px;
          background: #fff;

          .col {
            margin-top: 0px;

            .title,
            .subtitle {
              margin: 0px;
              margin-bottom: 10px;
            }

            .subtitle {
              font-size: 12px;
            }

            &.ltv-value-col {
              align-items: center;
              display: flex;
              justify-content: center;

              .ltv-value {
                font-size: 32px;
              }
            }
          }
        }
      }

      .product-overview {
        margin-top: 20px;

        .container {
          padding: 20px;
          background: #fff;

          .title,
          .subtitle {
            margin: 0px;
            margin-bottom: 10px;
          }

          .subtitle {
            font-size: 12px;
          }

          .overview-result {
            display: flex;
            justify-content: space-between;

            span {
              padding: 7px 0px;
            }

            &:last-child {
              border-bottom: none !important;
            }
          }
        }
      }
    }

    .disclaimer {
      font-size: 12px;
    }
  }

  .nav-container {
    width: 100%;
    padding: 20px 0px;

    .row {
      padding: 20px;

      .col {
        margin-top: 0px;
        text-align: center;

        h2,
        h4 {
          color: #fff;
          margin: 0px;
          margin-bottom: 10px;
        }
      }
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;

    .col {
      position: relative;
      width: 100%;
      min-height: 1px;
      // padding-right: 15px;
      padding-left: 15px;
      flex: 0 0 100%;
      max-width: 100%;
      margin-top: 30px;
    }

    @media screen and (min-width: 992px) {
      .col {
        position: relative;
        width: 100%;
        min-height: 1px;
        // padding-right: 15px;
        // padding-left: 15px;
        flex: 0 0 50%;
        max-width: 50%;
      }
    }

    @media screen and (min-width: 768px) {
      .col {
        position: relative;
        width: 100%;
        min-height: 1px;
        // padding-right: 15px;
        // padding-left: 15px;
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }

  .accordion-panel {
    padding: 0px 20px;
    margin: 20px 0;
    background: #fff;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;

    .amount-box {
      border: 1px solid #f4f4f4;
      text-align: center;
      position: relative;
      margin: 0px 5px;
      min-height: 105px;
      padding: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;

      input {
        border-top: none;
        border-left: none;
        border-right: none;
        padding: 0px;
        padding-bottom: 7px;
        outline: none;
        font-size: 25px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 5px;
        border-radius: 0px;
        width: 100%;
      }

      p {
        color: #969696;
        font-size: 15px;
        margin: 0px;
      }
    }

    .product-box {
      background: #f4f4f4;
      padding: 15px 15px 10px;

      h4 {
        margin-top: 0px;
      }

      select {
        width: 100%;
        padding: 10px 20px;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        border-top: none;
        border-left: none;
        border-right: none;
        outline: none;
        background: transparent;

        /* for Firefox */
        -moz-appearance: none;
        /* for Chrome */
        -webkit-appearance: none;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .illustration-page .illustration-page-loader {
    .load-circle {
      width: 550px;
      height: 550px;
    }

    .load-content {
      width: 450px;
      height: 450px;
    }
  }
}

@media screen and (max-width: 767px) {
  .illustration-page .illustration-page-loader {
    .load-circle {
      width: 350px;
      height: 350px;

      &::before {
        left: 30%;
      }
    }

    .load-content {
      width: 300px;
      height: 300px;

      h3 {
        font-size: 20px;
      }
    }
  }

  .illustration-page .result-container .product-result-summary {
    flex-direction: column;
  }

  .illustration-page .result-container .product-result-summary .product-result {
    flex: 100%;
  }

  .illustration-page .result-container .triangle-clip {
    display: none;
  }
}

@media screen and (max-width: 610px) {
  .illustration-page .switch-container {
    width: 80%;
  }
}

@media screen and (max-width: 425px) {
  .illustration-page .switch-container {
    width: 80%;
  }

  .illustration-page .illustration-page-loader {
    .load-circle {
      width: 250px;
      height: 250px;

      &:before {
        left: 25%;
      }
    }

    .load-content {
      width: 200px;
      height: 200px;

      h3 {
        font-size: 14px;
      }
    }
  }
}
