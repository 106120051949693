* {
  box-sizing: border-box;
}

.page {
  min-height: 100vh;
  position: relative;
  top: 0;
  overflow: hidden;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;

  .page-toolbar {
    height: 72px;
    padding: 20px;
    z-index: 1;
    box-sizing: content-box;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .flag-selector {
      min-width: 200px;

      .flag-selector-button {
        border-radius: 8px;
        border: none;

        &::after {
          border-top: none;
          border-bottom: none;
          border-left: none;
          border-right: none;
        }

        ~ ul {
          border-radius: 8px;
          border: none;
          box-shadow: 0 1px 28px 0 rgba($color: $black-color, $alpha: 0.22);
        }
      }
    }
  }

  .page-content {
    z-index: 1;
    flex: 1;
    overflow: auto;

    &.max-height {
      max-height: 100vh !important;
    }

    &.not-found {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      h1 {
        font-weight: 700;
        font-size: 40px;
        line-height: 1.4em;
        text-transform: uppercase;
        font-family: "Montserrat", sans-serif;
        margin: 0;
      }

      P {
        font-weight: 600;
        font-family: "Montserrat", sans-serif;
        line-height: 1.7em;
        font-size: 23px;
      }
    }
  }

  .page-footer {
    padding: 20px;

    .page-footer-text {
      max-width: 1050px;
      margin: auto;
      text-align: center;
      font-family: "Montserrat", sans-serif;
      color: white;
    }
  }

  .page-background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: top left;
    z-index: -1;

    .page-airplane {
      @include moving-object(80px, 400px, 20s);

      top: 180px;
      width: 400px;
      height: 20px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: top left;
    }

    .page-cloud-1 {
      @include moving-object(200px, 200px, 50s);

      top: 130px;
      height: 200px;
      width: 200px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: top left;
    }

    .page-cloud-2 {
      @include moving-object(200px, 200px, 55s);

      top: 150px;
      height: 200px;
      width: 200px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: top left;
    }
  }
}

.animation-wrapper {
  position: absolute;
  top: 0;
}
