@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import "./colors";
@import "./animation";
@import "./mixin";
@import "./misc";
@import "./responsive";

@font-face {
  font-family: "Interstate";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local("Interstate"),
    url("../fonts/interstate/Interstate-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Interstate";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local("Interstate"),
    url("../fonts/interstate/Interstate-Light.otf") format("opentype");
}

@font-face {
  font-family: "Interstate";
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: local("Interstate"),
    url("../fonts/interstate/Interstate-ExtraLight.otf") format("opentype");
}

@font-face {
  font-family: "Interstate";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local("Interstate"),
    url("../fonts/interstate/Interstate-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Rajdhani";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local("Rajdhani"),
    url("../fonts/Rajdhani/Rajdhani-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Rajdhani";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local("Rajdhani"),
    url("../fonts/Rajdhani/Rajdhani-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Rajdhani";
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local("Rajdhani"),
    url("../fonts/Rajdhani/Rajdhani-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Rajdhani";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local("Rajdhani"),
    url("../fonts/Rajdhani/Rajdhani-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Rajdhani";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local("Rajdhani"),
    url("../fonts/Rajdhani/Rajdhani-Bold.ttf") format("truetype");
}


@font-face {
  font-family: "Rajdhani";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local("Rajdhani"),
    url("../fonts/Rajdhani/Rajdhani-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "UrbaneDemi";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local("Rajdhani"),
    url("../fonts/Rajdhani/Rajdhani-Light.ttf") format("truetype");
}

@font-face {
  font-family: "UrbaneDemi";
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local("Rajdhani"),
    url("../fonts/Rajdhani/Rajdhani-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "UrbaneDemi";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local("Rajdhani"),
    url("../fonts/Rajdhani/Rajdhani-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "UrbaneDemi";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local("Rajdhani"),
    url("../fonts/Rajdhani/Rajdhani-Bold.ttf") format("truetype");
}

@font-face {
  font-family: 'UrbaneDemi';
  src: url('../fonts/UrbaneDemi/Urbane-LightItalic.eot');
  src: local('Urbane Light Italic'), local('Urbane-LightItalic'),
      url('../fonts/UrbaneDemi/Urbane-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/UrbaneDemi/Urbane-LightItalic.woff2') format('woff2'),
      url('../fonts/UrbaneDemi/Urbane-LightItalic.woff') format('woff'),
      url('../fonts/UrbaneDemi/Urbane-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'UrbaneDemi';
  src: url('../fonts/UrbaneDemi/Urbane-Medium.eot');
  src: local('Urbane Medium'), local('Urbane-Medium'),
      url('../fonts/UrbaneDemi/Urbane-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/UrbaneDemi/Urbane-Medium.woff2') format('woff2'),
      url('../fonts/UrbaneDemi/Urbane-Medium.woff') format('woff'),
      url('../fonts/UrbaneDemi/Urbane-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UrbaneDemi';
  src: url('../fonts/UrbaneDemi/Urbane-ThinItalic.eot');
  src: local('Urbane Thin Italic'), local('Urbane-ThinItalic'),
      url('../fonts/UrbaneDemi/Urbane-ThinItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/UrbaneDemi/Urbane-ThinItalic.woff2') format('woff2'),
      url('../fonts/UrbaneDemi/Urbane-ThinItalic.woff') format('woff'),
      url('../fonts/UrbaneDemi/Urbane-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'UrbaneDemi';
  src: url('../fonts/UrbaneDemi/Urbane-DemiBold.eot');
  src: local('Urbane Demi Bold'), local('Urbane-DemiBold'),
      url('../fonts/UrbaneDemi/Urbane-DemiBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/UrbaneDemi/Urbane-DemiBold.woff2') format('woff2'),
      url('../fonts/UrbaneDemi/Urbane-DemiBold.woff') format('woff'),
      url('../fonts/UrbaneDemi/Urbane-DemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UrbaneDemi';
  src: url('../fonts/UrbaneDemi/Urbane-Heavy.eot');
  src: local('Urbane Heavy'), local('Urbane-Heavy'),
      url('../fonts/UrbaneDemi/Urbane-Heavy.eot?#iefix') format('embedded-opentype'),
      url('../fonts/UrbaneDemi/Urbane-Heavy.woff2') format('woff2'),
      url('../fonts/UrbaneDemi/Urbane-Heavy.woff') format('woff'),
      url('../fonts/UrbaneDemi/Urbane-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UrbaneDemi';
  src: url('../fonts/UrbaneDemi/Urbane-Thin.eot');
  src: local('Urbane Thin'), local('Urbane-Thin'),
      url('../fonts/UrbaneDemi/Urbane-Thin.eot?#iefix') format('embedded-opentype'),
      url('../fonts/UrbaneDemi/Urbane-Thin.woff2') format('woff2'),
      url('../fonts/UrbaneDemi/Urbane-Thin.woff') format('woff'),
      url('../fonts/UrbaneDemi/Urbane-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UrbaneDemi';
  src: url('../fonts/UrbaneDemi/Urbane-BoldItalic.eot');
  src: local('Urbane Bold Italic'), local('Urbane-BoldItalic'),
      url('../fonts/UrbaneDemi/Urbane-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/UrbaneDemi/Urbane-BoldItalic.woff2') format('woff2'),
      url('../fonts/UrbaneDemi/Urbane-BoldItalic.woff') format('woff'),
      url('../fonts/UrbaneDemi/Urbane-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'UrbaneDemi';
  src: url('../fonts/UrbaneDemi/Urbane-ExtraLight.eot');
  src: local('Urbane Extra Light'), local('Urbane-ExtraLight'),
      url('../fonts/UrbaneDemi/Urbane-ExtraLight.eot?#iefix') format('embedded-opentype'),
      url('../fonts/UrbaneDemi/Urbane-ExtraLight.woff2') format('woff2'),
      url('../fonts/UrbaneDemi/Urbane-ExtraLight.woff') format('woff'),
      url('../fonts/UrbaneDemi/Urbane-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UrbaneDemi';
  src: url('../fonts/UrbaneDemi/Urbane-HeavyItalic.eot');
  src: local('Urbane Heavy Italic'), local('Urbane-HeavyItalic'),
      url('../fonts/UrbaneDemi/Urbane-HeavyItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/UrbaneDemi/Urbane-HeavyItalic.woff2') format('woff2'),
      url('../fonts/UrbaneDemi/Urbane-HeavyItalic.woff') format('woff'),
      url('../fonts/UrbaneDemi/Urbane-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'UrbaneDemi';
  src: url('../fonts/UrbaneDemi/Urbane-Bold.eot');
  src: local('Urbane Bold'), local('Urbane-Bold'),
      url('../fonts/UrbaneDemi/Urbane-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/UrbaneDemi/Urbane-Bold.woff2') format('woff2'),
      url('../fonts/UrbaneDemi/Urbane-Bold.woff') format('woff'),
      url('../fonts/UrbaneDemi/Urbane-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UrbaneDemi';
  src: url('../fonts/UrbaneDemi/Urbane-MediumItalic.eot');
  src: local('Urbane Medium Italic'), local('Urbane-MediumItalic'),
      url('../fonts/UrbaneDemi/Urbane-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/UrbaneDemi/Urbane-MediumItalic.woff2') format('woff2'),
      url('../fonts/UrbaneDemi/Urbane-MediumItalic.woff') format('woff'),
      url('../fonts/UrbaneDemi/Urbane-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'UrbaneDemi';
  src: url('../fonts/UrbaneDemi/Urbane-DemiBoldItalic.eot');
  src: local('Urbane Demi Bold Italic'), local('Urbane-DemiBoldItalic'),
      url('../fonts/UrbaneDemi/Urbane-DemiBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/UrbaneDemi/Urbane-DemiBoldItalic.woff2') format('woff2'),
      url('../fonts/UrbaneDemi/Urbane-DemiBoldItalic.woff') format('woff'),
      url('../fonts/UrbaneDemi/Urbane-DemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'UrbaneDemi';
  src: url('../fonts/UrbaneDemi/Urbane-Light.eot');
  src: local('Urbane Light'), local('Urbane-Light'),
      url('../fonts/UrbaneDemi/Urbane-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/UrbaneDemi/Urbane-Light.woff2') format('woff2'),
      url('../fonts/UrbaneDemi/Urbane-Light.woff') format('woff'),
      url('../fonts/UrbaneDemi/Urbane-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UrbaneDemi';
  src: url('../fonts/UrbaneDemi/Urbane-ExtraLightItalic.eot');
  src: local('Urbane Extra Light Italic'), local('Urbane-ExtraLightItalic'),
      url('../fonts/UrbaneDemi/Urbane-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/UrbaneDemi/Urbane-ExtraLightItalic.woff2') format('woff2'),
      url('../fonts/UrbaneDemi/Urbane-ExtraLightItalic.woff') format('woff'),
      url('../fonts/UrbaneDemi/Urbane-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}



@include themable(
  product-ruby,
  #ff385c,
  "../images/section_background.png",
  "../images/section_plane.png",
  "../images/section_cloud.png"
);
@include themable(
  product-royal,
  #002554,
  "../images/section_background.png",
  "../images/section_plane_royal.png",
  "../images/section_cloud_royal.png"
);
@include themable(
  product-hazelnut,
  #b1a296,
  "../images/section_background.png",
  "../images/section_plane_hazelnut.png",
  "../images/section_cloud_hazelnut.png"
);
@include themable(
  product-jade,
  #41b3a3,
  "../images/section_background.png",
  "../images/section_plane_jade.png",
  "../images/section_cloud_jade.png"
);
